import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ptBr from "../../config/texts/pt-br";
import images from "../../config/images";
import {
  ButtonContained,
  CheckBox,
  CustomText,
  InputEmail,
  InputPassword,
} from "../../genericComponents";
import Styles from "./LoginStyles";
import { FormFull } from "form-full";
import { MediaQueries, Spacing } from "../../config";
import { validations } from "../../utils";
import { api } from "../../services";
import { hooks, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";
import ImageLogin from "./image/imageLogin";
import packageJson from "../../../package.json";
import fonts from "../../config/fonts";

function Login() {
  const texts = ptBr.login;
  const version = packageJson.version;
  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const { addData, setIsLogged } = useContext(StorageContext);
  const Submit = async (data) => {
    call(null, api.getToken(data), (response) => {
      if (response.ok) {
        setIsLogged(true);
        addData("userData", response?.data?.user);
        SessionStorage.setItem(
          "token",
          response?.data?.type + " " + response?.data?.token
        );
      }
    });
  };

  const [reminder, setReminder] = React.useState(false);
  const [buttonDisabled, setButton] = React.useState(true);

  return (
    <FormFull onSubmit={Submit}>
      <Grid
        container
        spacing={0}
        style={{
          height: "100vh",
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
          [MediaQueries.mdDown]: {
            flexDirection: "row-reverse",
          },
        }}>
        <Grid item xs={12} sm={6} style={{ overflowX: "hidden" }}>
          <Styles.Container>
            <Styles.Header>
              <Styles.EcomLogo src={images.LogoHorizontal} />
            </Styles.Header>
            <Styles.Content>
              <Styles.Title>{texts.title}</Styles.Title>
              <Styles.Subtitle>{texts.subTitle}</Styles.Subtitle>
              <InputEmail
                name="username"
                label={texts.user}
                required={texts.userMessage}
              />
              <InputPassword
                name="password"
                label={texts.password}
                required={texts.passwordMessage}
                validation={validations.isPassword}
                onChange={() => {
                  setButton(false);
                }}
              />
              <CheckBox
                actualValue={reminder}
                name="reminder"
                label={texts.reminder}
                click={() => setReminder(!reminder)}
              />
              <ButtonContained
                name="submit"
                loading={loading}
                disabled={buttonDisabled}
                feedback={true}
                action="submit"
                type="submit"
                label={texts.enter}
              />
              <Grid container item>
                <Grid item xs={12} sm={7}>
                  <Styles.P gray>
                    Ainda não tem conta?
                    <Styles.Span onClick={() => navigate("/user_register")}>
                      Crie aqui
                    </Styles.Span>
                  </Styles.P>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Styles.P end onClick={() => navigate("/forgetPassword")}>
                    {texts.forgotPassword}
                  </Styles.P>
                </Grid>
              </Grid>
              <Styles.Version>
                <CustomText
                  fontSize={1.5}
                  fontFamily={fonts.light}
                  styles={{ color: "#C2C2C2", marginTop: Spacing(2) }}>
                  Minha Ecom v.{version} {process.env.REACT_APP_ENV}
                </CustomText>
              </Styles.Version>
            </Styles.Content>
          </Styles.Container>
        </Grid>

        <Grid item xs={0} sm={6}>
          <Styles.ContainerImage>
            <ImageLogin />
          </Styles.ContainerImage>
        </Grid>
      </Grid>
    </FormFull>
  );
}

export default Login;
