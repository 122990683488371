import React from "react";
import Styles from "./UserInfoStyles";
import { hooks, masks } from "../../../utils";
import { api } from "../../../services";
import Notification from "../Notification/notification";

function UserInfo() {
  const { call } = hooks.useRequest();
  const [formData, setFormData] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const mount = React.useCallback(() => {
    call(null, api.getUserData(), (response) => {
      if (response.ok) {
        setFormData(response?.data);
      } else {
      }
    });
  }, [call]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);
  const cpfOrCnpj = (value) => {
    let newValue;
    if (value.length <= 11) {
      newValue = masks.CPF(value);
    } else {
      newValue = masks.CNPJ(value);
    }
    return newValue;
  };

  return (
    <> 
    {formData ? <>
        <Notification />
        <Styles.Dot>
          <Styles.Letter>{formData?.nickname.substr(0, 1)}</Styles.Letter>
        </Styles.Dot>
        <Styles.Content>
          <Styles.Name>{formData?.nickname}</Styles.Name>
          <Styles.CpfCnpj>{cpfOrCnpj(formData?.cpfCnpj)}</Styles.CpfCnpj>
        </Styles.Content>
      </> : null}
      
    </>
  );
}

export default UserInfo;
