import { paths } from "../navigation/navigate";

const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login",
    },
    {
      navigationId: 1,
      path: paths.forgetPassword,
      name: "ForgetPassword",
    },
    {
      navigationId: 3,
      path: paths.userRegister,
      name: "UserRegister",
    },
  ],
  logged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Home",
      working: true,
    },
    {
      navigationId: 1,
      path: paths.reports,
      name: "Reports",
      working: true,
    },
    {
      navigationId: 2,
      path: paths.bulletins,
      name: "Bulletins",
      working: true,
    },
    {
      navigationId: 3,
      path: " ",
      name: "TalkToUs",
      working: true,
    },
    {
      navigationId: 4,
      path: paths.settings,
      name: "Settings",
      working: true,
    },
    {
      navigationId: 1,
      path: paths.reportReader,
      name: "ReportReader",
      working: false,
    },
  ],
  menu: [
    {
      groupTitle: "Geral",
      pages: [
        {
          navigationId: 0,
          path: paths.login,
          title: "Home",
          working: true,
        },
        {
          navigationId: 1,
          path: paths.reports,
          title: "Relatórios",
          working: true,
        },
        {
          navigationId: 2,
          path: paths.bulletins,
          title: "Comunicados",
          working: true,
        },
        {
          navigationId: 3,
          path: " ",
          title: "Fale conosco",
          working: true,
        },
      ],
    },
    {
      groupTitle: "Sistema",
      pages: [
        {
          navigationId: 4,
          path: paths.settings,
          title: "Configurações",
          working: true,
        },
        {
          navigationId: 5,
          title: "Sair",
          working: true,
        },
      ],
    },
  ],
};
export default pagesConfig;
