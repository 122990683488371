import React from "react";
import { FormHelperText, Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";
import { useNavigate } from "react-router-dom";
import {
  ButtonContained,
  ButtonText,
  CheckBox,
  InputCNPJandCPF,
  InputEmail,
  InputPassword,
} from "../../../genericComponents";
import Styles from "../NewAccountStyles";
import { FormFull } from "form-full";
import { masks, validations } from "../../../utils";

function StepNewAccount({ RenderSwiperDots, onSubmit, loading }) {
  const texts = ptBr.newAccount.createNewAccount;

  const navigate = useNavigate();

  const [buttonDisabled, setButton] = React.useState(true);
  const [ref, setRef] = React.useState();
  const [checkError, setCheckError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);

  const sendTokenData = async (r) => {
    const data = await r.testErrorsAndReturnData();
    if (check) {
      if (data?.data?.password === data?.data?.confirmPassword) {
        onSubmit(data?.data);
      } else {
        setPasswordError(true);
      }
    } else {
      setCheckError(true);
    }
  };

  const [check, setCheck] = React.useState(false);
  return (
    <FormFull formRef={setRef} onSubmit={() => {}}>
      <Styles.Content>
        <Styles.Title>{texts.title}</Styles.Title>
        <Styles.Subtitle>{texts.subTitle}</Styles.Subtitle>
        <InputCNPJandCPF
          name="cpfCnpj"
          label={texts.user}
          required={texts.userMessage}
          validation={validations.validateCNPJandCPF}
          maskToSubmit={masks.cpfCnpjToRequest}
        />
        <InputEmail
          name="email"
          label={texts.email}
          required={texts.userMessage}
          validation={validations.isEmailValid}
        />
        <Styles.InputName
          name="name"
          label={texts.name}
          required={texts.userMessage}
        />
        <InputPassword
          name="password"
          label={texts.password}
          required={texts.passwordMessage}
          validation={validations.isPassword}
        />
        <InputPassword
          name="confirmPassword"
          label={texts.confirmPassword}
          required={texts.passwordMessage}
          validation={validations.isPassword}
          onChange={() => {
            setButton(false);
          }}
        />
        <FormHelperText error={passwordError}>
          {Boolean(passwordError) ? "As senhas não correspondem." : " "}
        </FormHelperText>

        <CheckBox
          actualValue={check}
          name="terms"
          register
          click={(value) => setCheck(value)}
        />
        <FormHelperText error={checkError}>
          {Boolean(checkError) ? "Campo obrigatório." : " "}
        </FormHelperText>

        <ButtonContained
          name="continue"
          loading={loading}
          disabled={buttonDisabled}
          feedback={true}
          onClick={() => sendTokenData(ref)}
          label={texts.send}
        />
      </Styles.Content>

      <Styles.Footer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {RenderSwiperDots(texts.render)}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={7}
            style={{ display: "flex", justifyContent: "space-between" }}>
            <ButtonText
              name="back"
              loading={loading}
              disabled
              fullWidth={false}>
              <Styles.ArrowCircle>
                <Styles.ArrowLeft />
              </Styles.ArrowCircle>
              Voltar
            </ButtonText>
            <ButtonText
              name="next"
              action="submit"
              loading={loading}
              disabled={buttonDisabled}
              fullWidth={false}>
              Avançar
              <Styles.ArrowCircle>
                <Styles.ArrowRight />
              </Styles.ArrowCircle>
            </ButtonText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={5}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonText
              onClick={() => navigate("/")}
              name="home"
              loading={loading}
              fullWidth={false}>
              Voltar para o login
            </ButtonText>
          </Grid>
        </Grid>
      </Styles.Footer>
    </FormFull>
  );
}

export default StepNewAccount;
