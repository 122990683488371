import React from "react";
import Styles from "./formStyles";
import { InputPassword } from "../../../genericComponents";
import ptBr from "../../../config/texts/pt-br";
import { FormFull } from "form-full";
import { hooks, validations } from "../../../utils";
import { FormHelperText } from "@material-ui/core";
import { api } from "../../../services";
import { customModal } from "../../../genericComponents/modals/utils";

function FormChangePassword({ close, onSubmit }) {
  const texts = ptBr.forgetPassword;
  const [buttonDisabled, setButton] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState(false);

  const { call } = hooks.useRequest();

  const sendData = async (data) => {
    if (data.newPassword === data.confirmPassword) {
      const bodyRequest = {
        newPassword: data.newPassword,
        password: data.password,
      };
      call(null, api.putChangePassword(bodyRequest), (response) => {
        if (response.ok) {
          customModal.close();
          customModal.setInfos(
            "Senha alterada com sucesso!",
            ["Sua senha foi alterada no sistema com sucesso."],
            {
              label: "Ok",
              onClick: () => {
                customModal.close();
              },
            }
          );
        }
      });
    } else {
      setPasswordError(true);
    }
  };

  return (
    <FormFull onSubmit={sendData}>
      <Styles.Container>
        <InputPassword
          name="password"
          label="Senha atual"
          required={texts.passwordMessage}
          validation={validations.isPassword}
        />
        <InputPassword
          name="newPassword"
          label="Nova senha"
          required={texts.passwordMessage}
          validation={validations.isPassword}
        />
        <InputPassword
          name="confirmPassword"
          label="Confirmar nova senha"
          required={texts.passwordMessage}
          validation={validations.isPassword}
          onChange={() => {
            setButton(false);
          }}
        />
      </Styles.Container>

      <Styles.ErrorMessageContainer>
        <FormHelperText error={passwordError}>
          {Boolean(passwordError) ? "As senhas não correspondem." : " "}
        </FormHelperText>
      </Styles.ErrorMessageContainer>

      <Styles.PaddingModal>
        <Styles.ConfirmButton
          name="passwordChanged"
          disabled={buttonDisabled}
          action="submit">
          Confirmar nova senha
        </Styles.ConfirmButton>
        <Styles.ExitButton onClick={close}>
          Cancelar alteração
        </Styles.ExitButton>
      </Styles.PaddingModal>
    </FormFull>
  );
}

export default FormChangePassword;
