export const prefix = "";

export const paths = {
  login: prefix + "/",
  home: prefix + "/",
  forgetPassword: prefix + "/forgetPassword",
  userRegister: prefix + "/user_register",
  settings: prefix + "/settings",
  reports: prefix + "/reports",
  reportReader: prefix + "/report/:name/:typeId/:id/:subtitle",
  bulletins: prefix + "/bulletins",
};
