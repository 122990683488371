import { errorModal } from "../../genericComponents/modals/utils";

async function caller(methodFunc, endpoint, model, body, axiosConfig) {
  const response = await methodFunc(endpoint, body, axiosConfig);

  if (response.ok) {
    if (model) {
      return model(response);
    } else {
      return response;
    }
  } else {
    const errorMsg= response?.data?.message ? response?.data?.message:  response?.status + ": error from api"
    errorModal.setInfos(
      "Erro",
      errorMsg,
      { label: "OK", onClick: () => errorModal.close() },
      null,
      null,
      false
    );
    return Object.assign(new Error("error"), {
      message: "error from api",
      ...response,
      errorType: endpoint,
    });
  }
}

export default caller;
