import styled from "styled-components";
import React from "react";

import fonts from "../../../config/fonts";
import { IconButton } from "@material-ui/core";
import CustomText from "../../customText/CustomText";
import { Icons, MediaQueries } from "../../../config";

const FooterPage = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    paddingBlock: spacing(6),
    paddingInline: "20%",
    display: "flex",
    justifyContent: "space-between",
    [MediaQueries.smDown]: {
      paddingInline: "20%",
      alignItems: "center",
      flexDirection: "column",
    },
  };
});
const ButtonIcon = styled(IconButton)(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: 0,
    borderRadius: 0,
    marginRight: spacing(2),
    [MediaQueries.smDown]: {
      marginRight: spacing(4),
    },
  };
});

function Footer() {
  return (
    <FooterPage>
      <CustomText
        fontSize={3}
        fontFamily={fonts.regular}
        styles={{ color: "#C2C2C2" }}></CustomText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <>
          <ButtonIcon>
            <Icons.Linkedin
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/ecom-energia/posts/?feedView=all&viewAsMember=true"
                );
              }}
            />
          </ButtonIcon>
          <ButtonIcon>
            <Icons.Facebook
              onClick={() => {
                window.open("https://www.facebook.com/ecomenergia/");
              }}
            />
          </ButtonIcon>
          <ButtonIcon>
            <Icons.Insta
              onClick={() => {
                window.open("https://www.instagram.com/ecomenergia/");
              }}
            />
          </ButtonIcon>
          <ButtonIcon>
            <Icons.Youtube
              onClick={() => {
                window.open(
                  "https://www.youtube.com/channel/UC9cs7TzwX8vq4G4NcvoOiPA"
                );
              }}
            />
          </ButtonIcon>
        </>
      </div>
    </FooterPage>
  );
}

export default Footer;
