import React, { useContext } from "react";
import Fab from "@material-ui/core/Fab";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { StorageContext } from "./contexts/StorageContext";
import pagesConfig from "./config/pagesConfig";
import pages from "./pages";

import Header from "./pages/header/header";
import ThemeProviderComponent from "./genericComponents/others/ThemeProvider";
import { Colors, Icons, Spacing, Theme } from "./config";
import ImageLogin from "./pages/login/image/imageLogin";
import { Grid } from "@material-ui/core";
import { ButtonText } from "./genericComponents";
import { SessionStorage } from "./utils";
import Close from "@material-ui/icons/Close";

function App() {
  const { isLogged } = useContext(StorageContext);
  const [addOpen, setAddOpen] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);

  const mount = React.useCallback(async () => {
    var viewport_width = document.documentElement.clientWidth;
    const firstLogin = SessionStorage.getItem("firstLogin");

    if (firstLogin === null && viewport_width < 601) {
      setAddOpen(true);
    }
  }, [setAddOpen]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);
  return isLogged ? (
    <ThemeProviderComponent theme={Theme.Light}>
      <div
        style={{
          display: addOpen ? "initial" : "none",
          position: "absolute",
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
          zIndex: 999999,
        }}>
        <ButtonText
          fullWidth={false}
          style={{
            position: "absolute",
            color: "white",
            right: 0,
            margin: Spacing(2),
            width: Spacing(4),
            height: Spacing(4),
            minWidth: Spacing(4),
            padding: 0,
          }}
          startIcon={
            <Close style={{ width: Spacing(4), height: Spacing(4) }} />
          }
          onClick={() => {
            setAddOpen(false);
            SessionStorage.setItem("firstLogin", true);
          }}></ButtonText>
        <Grid container>
          <ImageLogin />
        </Grid>
      </div>
      <Router>
        <Header />
        <Fab
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send/?phone=5511950485087&text&type=phone_number&app_absent=0"
            )
          }
          style={{
            width: Spacing(8),
            height: Spacing(8),
            position: "absolute",
            bottom: "8%",
            right: "5%",
            backgroundColor: Colors.purple,
            color: "white",
            zIndex: 99999,
          }}>
          <Icons.Chatbot
            style={{ width: Spacing(3.5), height: Spacing(3.5) }}
          />
        </Fab>
        <Routes>
          {pagesConfig.logged.map((item, index) => {
            const Component = pages[item.name];
            return (
              <Route
                key={item.path + index}
                path={item.path}
                element={<Component />}
              />
            );
          })}
        </Routes>
      </Router>
    </ThemeProviderComponent>
  ) : (
    <ThemeProviderComponent theme={Theme.Light}>
      <Router>
        <Routes>
          {pagesConfig.notLogged.map((item, index) => {
            const Component = pages[item.name];
            return (
              <Route
                key={item.path + index}
                path={item.path}
                element={<Component />}
              />
            );
          })}
        </Routes>
      </Router>
    </ThemeProviderComponent>
  );
}

export default App;
