import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Colors, Spacing } from "../../config";

import FontStyles from "../styles/fontStyles";

import { useFormFull } from "form-full";
const StyledButton = styled(Button)(({ theme, $defaultColor, fullWidth }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: fullWidth ? Spacing(1.1, 2) : Spacing(1.1, 0.5),
    width: fullWidth ? "100%" : "fit-content",
    overflow: "hidden",
    ...FontStyles.bold16,
    color: $defaultColor ? Colors.black : Colors.purple,
  };
});
const StyledCircularProgress = styled(CircularProgress)(({ theme, $show }) => {
  //   const { palette: colors } = theme;
  return {
    position: "absolute",
    color: Colors.purple,
    transition: ".5s",
    opacity: $show ? 1 : 0,
  };
});

const ChildrenContainer = styled.div(({ $show }) => {
  return {
    transition: ".5s",
    opacity: $show ? 1 : 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

function ButtonTextComponent({
  fullWidth = true,
  label,
  children,
  type,
  disabled,
  loading,
  name,
  feedback,
  action,

  onClick: onClickProps,
  ...props
}) {
  const { onClick, formLoading, formDisabled } = useFormFull.button({
    feedback,
    action,
    onClick: onClickProps,
  });

  return (
    <StyledButton
      variant="text"
      loading={loading || formLoading}
      disabled={disabled || formDisabled}
      fullWidth={fullWidth}
      onClick={onClick}
      {...props}>
      <StyledCircularProgress size={Spacing(3)} $show={loading} />

      <ChildrenContainer $show={!loading}>{children}</ChildrenContainer>
    </StyledButton>
  );
}

export default ButtonTextComponent;
