import fonts from "../../config/fonts"

const FontStyles = {
  medium12: {
    fontSize: fonts.sizes.smaller,
    fontFamily: fonts.medium,
  },
  medium14: {
    fontSize: fonts.sizes.small,
    fontFamily: fonts.medium,
  },
  medium16: {
    fontSize: fonts.sizes.regular,
    fontFamily: fonts.medium,
  },
  medium22: {
    fontSize: fonts.sizes.large,
    fontFamily: fonts.medium,
  },
  medium42: {
    fontSize: fonts.sizes.extraLarge,
    fontFamily: fonts.medium,
  },
  roman12: {
    fontSize: fonts.sizes.smaller,
    fontFamily: fonts.roman,
  },
  roman14: {
    fontSize: fonts.sizes.small,
    fontFamily: fonts.roman,
  },
  roman16: {
    fontSize: fonts.sizes.regular,
    fontFamily: fonts.roman,
  },
  bold10: {
    fontSize: fonts.sizes.extraSmall,
    fontFamily: fonts.bold,
  },
  bold12: {
    fontSize: fonts.sizes.smaller,
    fontFamily: fonts.bold,
  },
  bold14: {
    fontSize: fonts.sizes.small,
    fontFamily: fonts.bold,
  },
  bold16: {
    fontSize: fonts.sizes.regular,
    fontFamily: fonts.bold,
  },
  bold18: {
    fontSize: fonts.sizes.medium,
    fontFamily: fonts.bold,
  },
  bold22: {
    fontSize: fonts.sizes.large,
    fontFamily: fonts.bold,
  },
  bold32: {
    fontSize: fonts.sizes.larger,
    fontFamily: fonts.bold,
  },
  bold42: {
    fontSize: fonts.sizes.extraLarge,
    fontFamily: fonts.bold,
  },
  semibold12: {
    fontSize: fonts.sizes.smaller,
    fontFamily: fonts.semibold,
  },
  semibold14: {
    fontSize: fonts.sizes.small,
    fontFamily: fonts.semibold,
  },
  semibold16: {
    fontSize: fonts.sizes.regular,
    fontFamily: fonts.semibold,
  },
  semibold22: {
    fontSize: fonts.sizes.large,
    fontFamily: fonts.semibold,
  },
  semibold24: {
    fontSize: fonts.sizes.larger,
    fontFamily: fonts.semibold,
  },
  light14: {
    fontSize: fonts.sizes.small,
    fontFamily: fonts.light,
  },
  italic14: {
    fontSize: fonts.sizes.small,
    fontFamily: fonts.italic,
  },
};

export default FontStyles;
