import Login from "./login/login";
import ForgetPassword from "./forgetPassword/forgetPassword";
import UserRegister from "./newAccount/NewAccount";
import Home from "./home/home";
import Settings from "./settings/settings";
import Reports from "./reports/reports";
import ReportReader from "./reportReader/reportReader";
import Bulletins from "./bulletins/bulletins";
const pages = {
  Login,
  ForgetPassword,
  Home,
  UserRegister,
  Settings,
  Reports,
  ReportReader,
  Bulletins,
};
export default pages;
