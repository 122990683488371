const colors = {
  // TODO/HINT REMOVER Cores projeto anterior
  whiteBackground: "#F6F6F6",
  whiteSmoke: "#E1E1E1",
  black: "#000000",
  mediumBlue: "#1E74BB",
  grayText: "#C2C2C2",
  grayDisabled: "#EFEFEF",
  orangeAlert: "#F59721",
  greenSuccess: "#6EBe44",

  softGrayHover: "#F1F1F1",
  darkGray2TextOpacity: "#4C4C4499",
  white: "#ffffff",
  redError: "#F0166D",
  lightBlue: "#5AB6E5",

  darkOpacity: "#00000020",
  purple2: "#36344D",
  lightBlueInput: "#396A82",
  orange: "#DEA017",

  // TODO/HINT pegar cores totais e mesclar aqui
  //
  //
  darkGray: "#3E3E3E",
  softGray: "#EEEEEE",
  lightGray: "#F6F6F6",
  purple: "#5C42DB",
  yellow: "#EAEF22",
};

export default colors;
