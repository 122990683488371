import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Button from "@material-ui/core/Button";
import { Colors, Spacing } from "../../../config";
import NotifItem from "./notifItem/notifItem";
import { api } from "../../../services";
import Styles from "./NotificationStyles";
import { hooks } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../navigation/navigate";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  popover: {
    minWidth: 300,
    maxWidth: 350,
    maxHeight: 400,
  },
  button: {
    minWidth: 10,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiTouchRipple-root": {
      color: "transparent !important",
    },
  },
}));

export default function Notification() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [formData, setFormData] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const classes = useStyles();
  const { call } = hooks.useRequest();
  const navigate = useNavigate();

  const mount = React.useCallback(() => {
    call(null, api.getNotifications(), (response) => {
      function deleteNotification(item) {
        call(null, api.deleteNotification(parseInt(item)));
      }
      let data = [];
      if (response.ok) {
        response?.data?.content?.map((item) => {
          let path = null;
          let name = null;
          if (item.bulletinId !== null) {
            path = " ";
            name = "Comunicados";
          } else if (item.reportTypeId !== null) {
            path = paths.reports;
            name = "Relatórios";
          }
          data.push({
            Title: name,
            id: item.id,
            text: item.text,
            onPress: () => {
              navigate(path);
              deleteNotification(item.id);
            },
          });
          return item;
        });
        setFormData(data);
      }
    });
  }, [call, navigate]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  function deleteAll() {
    call(null, api.deleteAllNotifications(), (response) => {
      if (response.ok) {
        setDisabled(true);
      }
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        className={classes.button}>
        <FiberManualRecordIcon
          style={{
            position: "absolute",
            bottom: "35%",
            left: "55%",
            width: 11,
            color: Colors.yellow,
          }}
        />
        <NotificationsIcon
          style={{ width: 25, height: 25, color: Colors.purple }}
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: Spacing(1.5),
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className={classes.popover}>
        <Styles.HeaderContainer
          onClick={() => {
            deleteAll();
          }}>
          <Styles.Text>Marcar todos como lido</Styles.Text>
          <Styles.IconCheck />
        </Styles.HeaderContainer>
        {formData?.map((element) => {
          return (
            <NotifItem
              title={element.Title}
              phrase={element.text}
              onclick={element.onPress}
              allDisabled={disabled}
            />
          );
        })}
      </Popover>
    </div>
  );
}
