// Configuração de modais customizados - Modais que podem ser customizados e chamados de outras telas
let customModalCallback = null;
function setCustomModalCallback(callback) {
  customModalCallback = callback;
}
function setCustomModalInfos(
  title,
  texts = [],
  confirmButton,
  exitButton,
  extraField,
  noExit
) {
  customModalCallback({
    open: true,
    infos: { title, texts, exitButton, confirmButton, extraField, noExit },
  });
}
function closeCustomModal() {
  customModalCallback({
    open: false,
    infos: {},
  });
}
const customModal = {
  setCallback: setCustomModalCallback,
  setInfos: setCustomModalInfos,
  close: closeCustomModal,
};

// Configuração de modais de erro - Modais de erro padrão;
let errorModalCallback = null;
function setErrorModalCallback(callback) {
  errorModalCallback = callback;
}
function setErrorModalInfos(title, text, confirmButton, exitButton) {
  errorModalCallback({
    open: true,
    infos: { title, text, exitButton, confirmButton },
  });
}
function closeErrorModal() {
  errorModalCallback({
    open: false,
    infos: {},
  });
}
const errorModal = {
  setCallback: setErrorModalCallback,
  setInfos: setErrorModalInfos,
  close: closeErrorModal,
};



export { customModal, errorModal };
