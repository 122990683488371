import styled from "styled-components";
import { Colors, Spacing } from "../../../config";
import { FontStyles } from "../../../genericComponents";
import CheckIcon from '@material-ui/icons/Check';

const HeaderContainer = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingInline: Spacing(2),
    paddingTop: Spacing(1),
    cursor: "pointer",
    minWidth: 250,
  };
});

const Text = styled.p(() => {
  return {
    color: Colors.purple,
    ...FontStyles.medium14,
  };
});

const IconCheck = styled(CheckIcon)(() => {
  return {
    color: Colors.purple,
    width: 16,
    marginLeft: Spacing(0.75),
  };
});

const Styles = {
  HeaderContainer,
  Text,
  IconCheck,
};

export default Styles;