import React from "react";
import { Grid } from "@material-ui/core";
import images from "../../config/images";
import { Swiper } from "../../genericComponents";
import Styles from "./NewAccountStyles";
import StepNewAccount from "./Steps/StepNewAccount";
import StepCode from "./Steps/StepCode";
import Completed from "./Steps/Completed";
import { hooks } from "../../utils";
import { api } from "../../services";
import ImageLogin from "../login/image/imageLogin";

function NewAccount() {
  const { loading, call } = hooks.useRequest();
  const [formData, setFormData] = React.useState({});

  const FinishStepOne = async (data, Callback) => {
    const bodyRequest = {
      email: data.email,
      cpfCnpj: data.cpfCnpj,
    };
    call(null, api.postRegistration(bodyRequest), (response) => {
      if (response.ok) {
        setFormData({ ...formData, ...data });
        Callback();
      } else {
      }
    });
  };

  const FinishStepTwo = async (data, Callback) => {
    const bodyRequest = {
      email: formData.email,
      code: data.code,
    };
    call(null, api.postValidation(bodyRequest), (response) => {
      if (response.ok) {
        setFormData({ ...formData, ...data, token: response?.data?.token });
        FinishStepThree(response?.data?.token, Callback);
      } else {
      }
    });
  };

  const FinishStepThree = async (data, Callback) => {
    const bodyRequest = {
      token: data,
      name: formData.name,
      email: formData.email,
      cpfCnpj: formData.cpfCnpj,
      password: formData.password,
    };
    call(null, api.postConfirmRegistration(bodyRequest), (response) => {
      if (response.ok) {
        setFormData({ ...formData, ...data });
        Callback();
      } else {
      }
    });
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction={"row"}
        wrap={"nowrap"}
        alignItems={"flex-start"}>
        <Grid item xs={12} sm={6}>
          <Styles.Container>
            <Styles.Header>
              <Styles.EcomLogo src={images.LogoLogin} />
            </Styles.Header>

            <Swiper
              screens={[
                ({ goNext, RenderSwiperDots, title = "outro" }) => (
                  <StepNewAccount
                    loading={loading}
                    onSubmit={(data) => {
                      FinishStepOne(data, goNext);
                    }}
                    goBack={() => {}}
                    RenderSwiperDots={RenderSwiperDots}
                  />
                ),
                ({ goNext, RenderSwiperDots, goPrevious }) => (
                  <StepCode
                    loading={loading}
                    onSubmit={(data) => {
                      FinishStepTwo(data, goNext);
                    }}
                    goBack={() => goPrevious()}
                    RenderSwiperDots={RenderSwiperDots}
                  />
                ),
                ({ goNext, RenderSwiperDots, goPrevious }) => (
                  <Completed
                    loading={loading}
                    goBack={() => goPrevious()}
                    RenderSwiperDots={RenderSwiperDots}
                  />
                ),
              ]}
            />
          </Styles.Container>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ImageLogin />
        </Grid>
      </Grid>
    </>
  );
}

export default NewAccount;
