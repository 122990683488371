import React from "react";
import { Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";
import images from "../../../config/images";
import Styles from "./ImageLoginStyles";
import { Spacing } from "../../../config";
import { customModal } from "../../../genericComponents/modals/utils";

function ImageLogin() {
  const texts = ptBr.banner;
  function comingSoon() {
    customModal.setInfos("Em breve nas lojas", [null], null, {
      label: "Ok",
      onClick: () => {
        customModal.close();
      },
    });
  }
  return (
    <Grid
      container
      xs={12}
      style={{
        height: "100vh",
      }}>
      <Styles.ImageBG $Image={`url(${images.WebBanner})`}>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center">
          <Styles.CellphoneContainer src={images.Cellphone} />
          <Styles.Text title>{texts.title}</Styles.Text>
          <Styles.Text>{texts.subTitle}</Styles.Text>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ paddingTop: Spacing(2.5) }}>
          <Styles.DownloadContainer
            src={images.GooglePlay}
            onClick={() => {
              comingSoon();
            }}
          />
          <Styles.DownloadContainer
            src={images.AppStore}
            onClick={() => {
              comingSoon();
            }}
          />
        </Grid>
      </Styles.ImageBG>
    </Grid>
  );
}

export default ImageLogin;
