import React from "react";
import Styles from "./HomeStyles";
import { api } from "../../services";
import { CustomText, Footer } from "../../genericComponents";
import { Colors, Icons, Spacing } from "../../config";
import fonts from "../../config/fonts";
import NoticeBox from "./components/NoticeBox";
import { hooks } from "../../utils";
import { useNavigate } from "react-router-dom";
import ReportBox from "./components/ReportBox";
import GraphHome from "./components/GraphHome";
import images from "../../config/images";

function formatDate(input) {
  let datePart = input.match(/\d+/g),
    year = datePart[0].substring(2),
    month = datePart[1];

  return month + "/" + year;
}

function Home() {
  const { loading, call } = hooks.useRequest();
  const navigate = useNavigate();
  const [data, setData] = React.useState(null);
  const [bulletins, setBulletins] = React.useState(null);
  const [listReports, setReports] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [mounted, setMounted] = React.useState(false);
  var viewport_width = document.documentElement.clientWidth;

  const handleNotice = (id) => {
    if (id === open) {
      setOpen(null);
    } else {
      setOpen(id);
    }
  };
  const mount = React.useCallback(async () => {
    call(null, api.getAllPLD(), (response) => {
      if (response.ok) {
        setData(response?.data);
      }
    });
    call(null, api.getBulletins(), (response) => {
      if (response.ok) {
        setBulletins(response?.data?.content);
      }
    });
    call(null, api.getReportType(), (response) => {
      if (response.ok) {
        let reportsType = {};
        response?.data?.content.forEach((item) => {
          reportsType[item.id] = {
            icon: item.image,
            title: item.name,
            subTitle: item.category,
            data: item.dateIssuance,
          };
        });
        call(null, api.getAllReport(), (data) => {
          if (data.ok) {
            let reports = [];
            data?.data?.content.forEach((item, index) => {
              let type = reportsType[item.reportTypeId];

              let objIndex = reports.findIndex(
                (obj) => obj.reportTypeId === item.reportTypeId
              );

              if (objIndex !== -1) {
                reports[objIndex].reportList.push({
                  id: item.id,
                  link: item.link,
                  date: item.dateReference,
                });
                reports[objIndex].options.push({
                  id: item.id,
                  value: formatDate(item.dateReference),
                  label: formatDate(item.dateReference),
                  name: formatDate(item.dateReference),
                  trueDate: item.dateReference,
                });
              } else {
                reports.push({
                  reportTypeId: item.reportTypeId,
                  icon: type.icon,
                  title: type.title,
                  subTitle: type.subTitle,
                  reportList: [
                    {
                      id: item.id,
                      link: item.link,
                      date: item.dateReference,
                    },
                  ],
                  options: [
                    {
                      id: item.id,
                      value: formatDate(item.dateReference),
                      label: formatDate(item.dateReference),
                      name: formatDate(item.dateReference),
                      trueDate: item.dateReference,
                    },
                  ],
                });
              }
              reports.sort((a, b) => {
                if (
                  parseInt(a.subTitle.replace("C", "")) >
                  parseInt(b.subTitle.replace("C", ""))
                ) {
                  return 1;
                } else if (
                  parseInt(a.subTitle.replace("C", "")) <
                  parseInt(b.subTitle.replace("C", ""))
                ) {
                  return -1;
                }
                return 0;
              });
              setReports(reports);
            });
          }
        });
      }
    });
  }, [call]);

  React.useEffect(() => {
    if (!mounted) {
      mount();
      setMounted(true);
    }
  }, [mount, mounted]);

  const orderReports = (item) => {
    let newItem = item;
    const newOrder = item?.options?.sort((a, b) => {
      if (new Date(a?.trueDate).getTime() > new Date(b?.trueDate).getTime()) {
        return -1;
      }
      if (new Date(a?.trueDate).getTime() < new Date(b?.trueDate).getTime()) {
        return 1;
      }
      return 0;
    });

    newItem.options = newOrder;
    return newItem;
  };
  return (
    <Styles.Container>
      <Styles.Content marginTop>
        <CustomText
          fontSize={6}
          fontFamily={fonts.bold}
          textColor
          styles={{ marginTop: 0, display: "flex", alignItems: "center" }}>
          <Icons.Indicadores
            style={{
              marginRight: Spacing(3),
              filter:
                "invert(85%) sepia(23%) saturate(4488%) hue-rotate(246deg) brightness(90%) contrast(89%)",
            }}
          />
          Indicadores
        </CustomText>
        <GraphHome data={data} loading={loading} />
      </Styles.Content>

      {/*  ----            Relatorios               ------------ */}

      <Styles.Content alternative $Image={images.ReportBackground}>
        <Styles.Header>
          <CustomText
            fontSize={6}
            fontFamily={fonts.bold}
            styles={{
              marginTop: 0,
              display: "flex",
              alignItems: "center",
              color: Colors.purple,
            }}>
            <Icons.Clipboard
              style={{
                marginRight: Spacing(3),
                filter:
                  "invert(85%) sepia(23%) saturate(4488%) hue-rotate(246deg) brightness(90%) contrast(89%)",
              }}
            />
            Relatórios
          </CustomText>
        </Styles.Header>
        <Styles.RowTwo centralized>
          {listReports?.length > 0 &&
            listReports?.map((item, index) => {
              if (viewport_width > 600) {
                if (index < 4) {
                  return <ReportBox key={index} item={orderReports(item)} />;
                } else {
                  return null;
                }
              } else {
                if (index < 3) {
                  return <ReportBox key={index} item={orderReports(item)} />;
                } else {
                  return null;
                }
              }
            })}
        </Styles.RowTwo>
        <Styles.Header>
          <Styles.SmallButton
            fullWidth={false}
            label="Ver todos"
            onClick={() => navigate("/reports")}
          />
        </Styles.Header>
      </Styles.Content>

      {/*  ----            Comunicados               ------------ */}
      <Styles.Content>
        <Styles.Header>
          <CustomText
            fontSize={6}
            fontFamily={fonts.bold}
            textColor
            styles={{ marginTop: 0, display: "flex", alignItems: "center" }}>
            <Icons.Megaphone
              style={{
                marginRight: Spacing(3),
                filter:
                  "invert(85%) sepia(100%) saturate(4488%) hue-rotate(246deg) brightness(90%) contrast(89%)",
              }}
            />
            Comunicados
          </CustomText>
        </Styles.Header>
        {bulletins &&
          bulletins?.map((item, index) => {
            return (
              <NoticeBox
                key={index}
                title={item.name}
                content={item?.resume?.split("\n\n")}
                time={item?.date}
                open={index === open ? true : false}
                setOpen={() => handleNotice(index)}
              />
            );
          })}

        <Styles.Header>
          <Styles.SmallButton
            onClick={() => navigate("/bulletins")}
            fullWidth={false}
            label="Ver todos"
          />
        </Styles.Header>
      </Styles.Content>

      {/*  ----            Footer               ------------ */}
      <Footer />
    </Styles.Container>
  );
}

export default Home;
