import React from "react";
import { Grid } from "@material-ui/core";
import ptBr from "../../../config/texts/pt-br";
import { ButtonContained, ButtonText } from "../../../genericComponents";
import Styles from "../NewAccountStyles";
import { useNavigate } from "react-router-dom";

function Completed({ RenderSwiperDots, onSubmit, goBack, loading }) {
  const texts = ptBr.newAccount.success;

  const navigate = useNavigate();

  return (
    <>
      <Styles.Content>
        <Styles.Title>{texts.title}</Styles.Title>
        <Styles.Subtitle> {texts.subTitle}</Styles.Subtitle>

        <ButtonContained
          name="continue"
          loading={loading}
          onClick={() => navigate('/')}
          label={texts.newAccount}
        />
      </Styles.Content>

      <Styles.Footer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {RenderSwiperDots(texts.render)}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={7}
            style={{ display: "flex", justifyContent: "space-between" }}>
            <ButtonText
              name="back"
              loading={loading}
              onClick={goBack}
              fullWidth={false}>
              <Styles.ArrowCircle>
                <Styles.ArrowLeft />
              </Styles.ArrowCircle>
              Voltar
            </ButtonText>
            <ButtonText
              name="next"
              loading={loading}
              disabled
              fullWidth={false}>
              Avançar
              <Styles.ArrowCircle>
                <Styles.ArrowRight />
              </Styles.ArrowCircle>
            </ButtonText>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={5}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonText onClick={() => navigate('/')} name="home" loading={loading} fullWidth={false}>
              Voltar para o login
            </ButtonText>
          </Grid>
        </Grid>
      </Styles.Footer>
    </>
  );
}

export default Completed;
