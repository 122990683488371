import React from "react";
import clsx from "clsx";
import Styles from "./HeaderStyles";
import { Colors, MediaQueries, Spacing } from "../../config";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, AppBar, Toolbar, Grid } from "@material-ui/core";
import UserInfo from "./UserInfo/userInfo";
import Menu from "../menu/menu";
import images from "../../config/images";

const drawerWidth = 480;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: theme.spacing(17),
  },
  appBar: {
    height: theme.spacing(17),
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: Colors.white,
    boxShadow: "none",
    padding: Spacing(2, 20, 0),
    //960
    [MediaQueries.mdDown]: {
      padding: Spacing(2, 1, 0),
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
  },
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const [open] = React.useState(false);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}>
        <Toolbar>
          <Grid
            container
            xs={4}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <Menu />
          </Grid>
          <Grid
            container
            xs={4}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Styles.EcomLogo src={images.Logo} />
          </Grid>
          <Grid
            container
            xs={4}
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <UserInfo />
          </Grid>
        </Toolbar>
        <Styles.Line />
      </AppBar>
    </div>
  );
}
