import React from "react";
import { CustomText, FontStyles } from "../../../genericComponents";
import Styles from "../HomeStyles";
import { Colors, Fonts, MediaQueries, Spacing } from "../../../config";
import models from "../../../services/Models";
import Chart from "chart.js";
import { ListItem, withStyles } from "@material-ui/core";

const SelectItens = withStyles({
  root: {
    backgroundColor: "transparent",
    color: Colors.grayText,
    ...FontStyles.bold16,
    justifyContent: "center",
    "&$selected": {
      backgroundColor: "transparent",
      color: Colors.purple,
    },
    "&$selected:hover": {
      backgroundColor: Colors.purple,
      color: Colors.white,
    },
    "&:hover": {
      backgroundColor: Colors.purple,
      color: Colors.white,
    },
  },
  selected: {},
})(ListItem);

function GraphHome({ item, data, loading }) {
  const [region, setRegion] = React.useState(0);
  const [canvasData, setCanvas] = React.useState(null);
  const chartRef = React.useRef(null);
  const [a, setChart] = React.useState();
  const [regions] = React.useState([
    { label: "Norte", id: 0, filter: "Norte", initial: "N" },
    { label: "Sul", id: 1, filter: "Sul", initial: "S" },
    {
      label: "Sudeste",
      id: 2,
      filter: "Sudeste/Centro Oeste",
      initial: "SE/CO",
    },
    { label: "Nordeste", id: 3, filter: "Nordeste", initial: "NE" },
  ]);

  React.useEffect(() => {
    if (canvasData?.data?.chartData && !loading) {
      if (a !== null && a !== undefined) {
        a.destroy();
      }
      let b = new Chart(chartRef.current, canvasData?.data?.chartData);
      setChart(b);
    }
    // eslint-disable-next-line
  }, [canvasData, loading]);

  React.useEffect(() => {
    const filterData = async (region) => {
      const newData = await data?.filter(
        (s) => s?.submarket === regions[region]?.filter
      );
      var viewport_width = document.documentElement.clientWidth;

      const newCanvas = await models.DecimalLineChart(
        newData,
        viewport_width < 599 ? 6 : 12
      );

      setCanvas(newCanvas);
    };

    filterData(region);
  }, [region, data, regions]);

  const handleChange = (event) => {
    a?.destroy();
    setRegion(event.target.value);
  };
  const renderChart = React.useCallback(() => {
    return chartRef ? (
      <>
        <canvas
          style={{
            maxWidth: "100%",
            maxHeight: "400px",
          }}
          ref={chartRef}></canvas>
      </>
    ) : null;
  }, [chartRef]);
  return (
    <>
      <Styles.Card>
        <Styles.CardHeader>
          <Styles.CardTitle>Histórico de PLD mensal (R$/MWh)</Styles.CardTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Styles.SelectRegion
              disableUnderline
              id="demo-controlled-open-select"
              value={region}
              renderValue={(item, a) => (
                <div style={{ display: "flex", flexDirection: "row", marginRight: Spacing(3) }}>
                  <Styles.SelectLabel htmlFor="demo-controlled-open-select">
                    Região:{" "}
                  </Styles.SelectLabel>
                  {regions[item]?.initial}
                </div>
              )}
              onChange={handleChange}>
              {regions.map((item) => {
                return (
                  <SelectItens value={item.id}>
                    {item.initial} - {item.label}
                  </SelectItens>
                );
              })}
            </Styles.SelectRegion>
          </div>
        </Styles.CardHeader>
        {renderChart()}
        <Styles.Block>
          <Styles.Footer>Fonte: CCEE</Styles.Footer>
        </Styles.Block>
      </Styles.Card>
      <Styles.ShadowCard>
        <Styles.Row>
          <Styles.VerticalLine>
            <Styles.ValueCard>
              <CustomText fontFamily={Fonts.bold} fontSize={2} textColor={2}>
                R$ MW/h
              </CustomText>
              <CustomText fontFamily={Fonts.bold} fontSize={8} textColor={2}>
                {`${canvasData?.dataAverage}`.replace(".", ",")}
              </CustomText>
              <CustomText
                textColor={2}
                fontSize={3}
                styles={{ [MediaQueries.smDown]: { textAlign: "center" } }}>
                PLD médio últimos 6 meses
              </CustomText>
            </Styles.ValueCard>
          </Styles.VerticalLine>

          <Styles.VerticalLine>
            <Styles.ValueCard>
              <CustomText fontFamily={Fonts.bold} fontSize={2} textColor={2}>
                R$ MW/h
              </CustomText>
              <CustomText fontFamily={Fonts.bold} fontSize={8} textColor={2}>
                {`${canvasData?.lastMonth?.value}`.replace(".", ",")}
              </CustomText>
              <CustomText
                textColor={2}
                fontSize={3}
                styles={{ [MediaQueries.smDown]: { textAlign: "center" } }}>
                PLD mês anterior ({canvasData?.lastMonth?.date})
              </CustomText>
            </Styles.ValueCard>
          </Styles.VerticalLine>

          <div style={{ flex: 1 }}>
            <Styles.ValueCard>
              <CustomText fontFamily={Fonts.bold} fontSize={2} textColor={2}>
                R$ MW/h
              </CustomText>
              <CustomText fontFamily={Fonts.bold} fontSize={8} textColor={2}>
                {`${canvasData?.currentMonth?.value}`.replace(".", ",")}
              </CustomText>
              <CustomText
                textColor={2}
                fontSize={3}
                styles={{ [MediaQueries.smDown]: { textAlign: "center" } }}>
                PLD mês atual ({canvasData?.currentMonth?.date})
              </CustomText>
            </Styles.ValueCard>
          </div>
        </Styles.Row>
      </Styles.ShadowCard>
    </>
  );
}

export default GraphHome;
