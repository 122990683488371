import styled from "styled-components";
import { Colors, MediaQueries, Spacing } from "../../../config";
import FontStyles from "../../../genericComponents/styles/fontStyles";

const Dot = styled.div(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    height: Spacing(5),
    width: Spacing(5),
    borderRadius: Spacing(20),
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.softGray,
    margin: Spacing(0, 0.5, 0, 1.5),
    [MediaQueries.smDown]: {
      display: "none",
    },
  };
});

const Letter = styled.p(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    color: Colors.purple,
    textTransform: "uppercase",
    ...FontStyles.bold22,
  };
});

const Content = styled.div(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    flexDirection: "column",
    lineHeight: "0.3",
    marginLeft: Spacing(2),
    [MediaQueries.smDown]: {
      display: "none",
    },
  };
});

const Name = styled.p(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    color: Colors.purple,
    ...FontStyles.bold16,
  };
});

const CpfCnpj = styled.p(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    color: Colors.grayText,
    ...FontStyles.medium16,
  };
});

const Styles = {
  Dot,
  Letter,
  Content,
  Name,
  CpfCnpj,
};

export default Styles;
