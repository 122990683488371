import styled from "styled-components";
import { Colors, MediaQueries, Spacing } from "../../config";
import FontStyles from "../../genericComponents/styles/fontStyles";

const Title = styled.p(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold42,
    color: Colors.purple,
    marginTop: Spacing(4),
    marginBottom: 0,
  };
});

const Text = styled.p(({ theme }) => {
  // const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold22,
    color: Colors.purple,
    paddingLeft: Spacing(2),
    [MediaQueries.smDown]: {
      display: "none",
    },
  };
});

const Styles = {
  Title,
  Text,
};

export default Styles;
