import { create } from "apisauce";
import caller from "./helpers/caller";

import { SessionStorage } from "../utils";
import models from "./Models";

const apiUrl = process.env.REACT_APP_API;

const api = create({
  baseURL: apiUrl,
  timeout: 500000,
});

api.addRequestTransform((request) => {
  const token = SessionStorage.getItem("token");

  if (token) {
    request.headers.authorization = token;
  }
});

api.addMonitor((response) => {
  const token = response.headers.authorization;

  if (token) {
    SessionStorage.setItem("token", token);
  }
});

async function getToken(loginData) {
  return caller(api.post, "/public/sign-in", null, loginData);
}
async function sendRecoveryPasswordRequest(data) {
  return caller(api.post, `/public/password-reset`, null, data);
}

async function recoveryPasswordValidate(data) {
  //validate email code
  return caller(api.post, "/public/password-reset/validate", null, data);
}

async function sendRecoveryPasswordNewPassword(data) {
  //password reset
  return caller(api.post, "/public/password-reset/confirm", null, data);
}

async function changePassword(data) {
  return caller(api.post, "/user/change_password", null, data);
}

async function postRegistration(data) {
  //register
  return caller(api.post, "/public/sign-up/request", null, data);
}

async function postValidation(data) {
  //validation register
  return caller(api.post, "/public/sign-up/validate", null, data);
}

async function postConfirmRegistration(data) {
  //confirm register
  return caller(api.post, "/public/sign-up/confirm", null, data);
}

async function getUserData(data) {
  //confirm register
  return caller(api.get, "/users/me", null, data);
}

async function getBulletins(size = 4) {
  //confirm register
  return caller(api.get, `/bulletins?page=0&size=${size}`, null, null);
}

async function getPLD(id) {
  return models.DecimalLineChart({
    data: [210, 390, 420, 550, 205, 415, 380, 200, 400, 250, 600],
    label: [
      "10/01",
      "11/01",
      "12/01",
      "13/01",
      "10/01",
      "10/01",
      "10/01",
      "10/01",
      "10/01",
      "10/01",
      "10/01",
    ],
  });
}

async function getAllPLD(data) {
  //confirm register
  return caller(api.get, "/public/pld/months", null, null);
}

async function getReportType(data) {
  //confirm register
  return caller(api.get, "/public/report-types", null, null);
}

async function getAllReport(data) {
  //confirm register
  return caller(api.get, "/reports", null, null);
}

async function getPDF(data) {
  //confirm register
  return caller(api.get, `/public/reports/${data}/pdf`, null, null, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
    },
  });
}

async function putChangePassword(data) {
  //confirm register
  return caller(api.put, "/users/me/password", null, data);
}

async function getNotifications(data) {
  return caller(api.get, "/notifications", null, null);
}

async function deleteAllNotifications(data) {
  return caller(api.delete, "/notifications", null, data);
}

async function deleteNotification(id) {
  return caller(api.delete, `/notifications/${id}`, null, null);
}

const apiServices = {
  sendRecoveryPasswordRequest,
  sendRecoveryPasswordNewPassword,
  recoveryPasswordValidate,
  changePassword,
  getToken,
  postRegistration,
  postValidation,
  postConfirmRegistration,
  getPLD,
  getUserData,
  getAllPLD,
  getBulletins,
  getReportType,
  getAllReport,
  getPDF,
  putChangePassword,
  getNotifications,
  deleteAllNotifications,
  deleteNotification,
};

export default apiServices;
