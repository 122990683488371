import React from "react";

import { FormControl, Input, FormHelperText } from "@material-ui/core";

import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { IconButton } from "@material-ui/core";
import { useFormFull } from "form-full";
import { Colors, Spacing } from "../../config";
import FontStyles from "../styles/fontStyles";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
const Label = styled.p(({ theme, $withValue, $withError }) => {
  return {
    ...FontStyles.bold16,
    fontFamily: $withValue
      ? FontStyles.bold16.fontFamily
      : FontStyles.medium16.fontFamily,
    // textTransform: "uppercase",
    margin: 0,
    position: "absolute",
    zIndex: 999,
    top: !$withValue ? Spacing(2.3) : Spacing(-1),
    left: !$withValue ? Spacing(1) : 0,
    color: $withError ? "red" : $withValue ? Colors.purple : "#C2C2C2",
    transition: ".5s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
  };
});

const StyledInput = styled(Input)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $alternativeColors,
    ...rest
  }) => {
    return {
      padding: $small ? "0px 0px  0px  8px" : "0px 8px  0px  8px",
      marginTop: Spacing(1.5),
      multiline: true,
      textOverflow: "ellipsis",
      color: error ? "red" : "black",
      backgroundColor:
        $alternativeColors === 2 ? "gray" : "white" ? "transparent" : "gray",

      borderBottom: error ? "1px solid red" : "1px solid #EFEFEF",
      "& .MuiInput-input": {
        color: error ? "red" : $alternativeColors ? "black" : "white",

        "&::placeholder": {
          opacity: 0.8,
        },
        "&input:-internal-autofill-selected": {
          color: "red",
          background: "red",
        },
        "& .MuiInputBase-input": {
          color: error ? "red" : Colors.darkGray,
        },
      },
      "& .MuiInputBase-input": {
        color: error ? "red" : Colors.darkGray,
      },
      ...$inputStyle,
    };
  }
);

const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  return {
    color: "red",
    minHeight: Spacing(2.5),
  };
});

function getErrorClassname(base, error, valid) {
  const className = base;
  if (error) return `${className} invalid`;
  if (valid) return `${className} valid`;
  return className;
}

function getHint(error) {
  if (error) return error;

  return null;
}
function InputComponent(props) {
  const { value, name, error, onBlur, ref, valid, onChange, ffHandler } =
    useFormFull.field(props);

  const {
    id,
    label,
    required,
    disableError,
    hideVisualError,
    type,
    withHide,
    placeholder,
    state,
  } = props;

  return (
    <FormControl
      className={getErrorClassname("form-control", error, valid)}
      fullWidth
      error={hideVisualError ? null : Boolean(error)}>
      <Label
        className="form-input-label"
        $withValue={Boolean(value) || value === 0 || Boolean(placeholder)}
        $withError={Boolean(error)}>
        {label}
      </Label>
      <StyledInput
        error={error}
        id={id}
        type={type ? type : null}
        disableUnderline={true}
        name={name}
        value={value}
        label={label}
        ref={ref}
        required={!!required}
        onChange={(event) => {
          onChange(event, event.target.value);
        }}
        onBlur={() => {
          ffHandler?.testFieldError(name, false);
          onBlur();
        }}
        className="form-input"
      />
      {disableError ? null : (
        <ErrorMessage error={Boolean(error)}>{getHint(error)}</ErrorMessage>
      )}

      {withHide ? (
        <IconButton
          onClick={withHide}
          component="span"
          style={{
            position: "absolute",
            right: 0,
            top: "4px",
            color: "gray",
          }}>
          {state ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      ) : null}
    </FormControl>
  );
}

export default withTheme(InputComponent);
