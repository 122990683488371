import WebBanner from "../assets/images/banner-web-bg.png";
import AppStore from "../assets/images/Download_on_the_App_Store_Badge.png";
import GooglePlay from "../assets/images/Google_Play_Store_badge_EN.png";
import Cellphone from "../assets/images/app-mockup-sombra.png";

import Logo from "../assets/logos//Ecom_RGB__horizontal_roxo-amarelo.svg";
import LogoVertical from "../assets/logos/Ecom_RGB__vertical_tagline_roxo-amarelo.svg";
import LogoHorizontal from "../assets/logos/Ecom_RGB__horizontal_tagline_roxo-amarelo.svg";

import ReportBackground from "../assets/images/bg-relatorios.png";

const images = {
  WebBanner,
  AppStore,
  GooglePlay,
  Cellphone,

  Logo,
  LogoVertical,
  LogoHorizontal,

  ReportBackground,
};

export default images;
