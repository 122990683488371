import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { Spacing } from '../../../../config';
import Styles from './NotifItemStyles';

export default function NotifItem({ title, phrase,  onclick, allDisabled }) {
  const [disabled, setDisabled] = React.useState(false);
  return (
    <>
    {disabled || allDisabled ? 
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{paddingInline: Spacing(2), marginTop: Spacing(2), overflowY: "auto"}}
      >
        <Grid container direction="column" item xs={6}>
          <Styles.Text title disabled>{title}</Styles.Text>
          <Styles.Text disabled>{phrase}</Styles.Text>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={6}>
          <Styles.IconArrow disabled/>
        </Grid>
      </Grid>
    </> 
    :
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        onClick={() => {onclick(); setDisabled(true)}}
        style={{paddingInline: Spacing(2), marginTop: Spacing(2), cursor: "pointer"}}
      >
        <Grid container direction="column" item xs={6}>
          <Styles.Text title>{title}</Styles.Text>
          <Styles.Text>{phrase}</Styles.Text>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={6}>
          <Styles.IconArrow />
        </Grid>
      </Grid>
    </>}
    <Divider style={{marginTop: Spacing(2), opacity: "0.5"}}/>
    </>
  );
}
