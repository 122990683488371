const baseRequiredMessage = "Campo obrigatório.";
// const baseRequiredFieldsDescription = "* Preenchimento obrigatório";

const ptBr = {
  months: [
    { initials: "Jan", fullName: "Janeiro" },
    { initials: "Fev", fullName: "Fevereiro" },
    { initials: "Mar", fullName: "Março" },
    { initials: "Abr", fullName: "Abril" },
    { initials: "Mai", fullName: "Maio" },
    { initials: "Jun", fullName: "Junho" },
    { initials: "Jul", fullName: "Julho" },
    { initials: "Ago", fullName: "Agosto" },
    { initials: "Set", fullName: "Setembro" },
    { initials: "Out", fullName: "Outubro" },
    { initials: "Nov", fullName: "Novembro" },
    { initials: "Dez", fullName: "Dezembro" },
  ],
  version: "Bewiki Admin Web ",
  modals: {
    success: {
      buttonLabel: "OK",
    },
    errors: {
      buttonLabel: "Ok",
    },
    information: {
      closeInfo: "Fechar Informações",
    },
  },

  login: {
    title: "Faça login",
    subTitle: "Entre aqui com seus dados para acessar o sistema!",
    reminder: "Continuar conectado",
    forgotPassword: "Esqueci minha senha",
    enter: "Entrar",
    password: "Senha",
    passwordMessage: baseRequiredMessage,
    user: "Email",
    userMessage: baseRequiredMessage,
  },

  banner: {
    title: "Baixe nosso app",
    subTitle: "Disponível na Play Store e na App Store!",
  },

  forgetPassword: {
    titleStepOne: "Recupere sua senha",
    titleStepTwo: "Confirme seu e-mail",
    titleStepTree: "Redefina sua senha",
    titleStepFour: "Senha redefinida com sucesso",
    subTitle: [
      "Vamos enviar um código de verificação para seu email e você poderá redefinir sua senha!",
      "Código enviado, insira abaixo o código recebido em seu email para redefinir sua senha!",
      "Vamos enviar um código de verificação para seu email e você poderá redefinir sua senha!",
      "Sua senha foi redefinida com sucesso, vamos redirecionar você para a tela de login ou você pode clicar para voltar.",
      "Senha redefinida",
    ],
    continue: [
      "Enviar código",
      "Redefinir senha",
      "Confirmar senha",
      "Fazer login",
    ],
    resendCode: "Reenviar código de confirmação",
    forgotPassword: "Esqueci minha senha",
    enter: "Entrar",
    password: "Senha",
    onBackMenu: "Voltar para o login",
    onBack: "Voltar",
    onNext: "Avançar",
    confirmPassword: "Confirmar Senha",
    passwordMessage: baseRequiredMessage,
    user: "Email",
    userMessage: baseRequiredMessage,
  },

  newAccount: {
    createNewAccount: {
      title: "Crie sua conta",
      subTitle: "Entre com seus dados para criar sua conta!",
      user: "CNPJ ou CPF",
      name: "Nome completo",
      email: "Email",
      userMessage: baseRequiredMessage,
      password: "Senha",
      confirmPassword: "Confirmar senha",
      passwordMessage: baseRequiredMessage,
      termsOfUse: "Li e concordo com os termos de uso",
      send: "Enviar confirmação",
      render: "Inserção de dados",
    },
    emailCode: {
      title: "Confirme seu e-mail",
      subTitle: "Um código de confirmação foi enviado para seu email.",
      newAccount: "Criar conta",
      render: "Confirmação de e-mail",
    },
    success: {
      title: "Conta criada com sucesso",
      subTitle:
        "Sua conta foi criada com sucesso, vamos redirecionar você para a tela de login ou você pode clicar para voltar.",
      newAccount: "Fazer login",
      render: "Conta criada",
    },
  },

  settings: {
    title: "Configurações",
    subTitle: ["Como quero ser chamado", "Email", "Senha"],
  },
};

export default ptBr;
